

/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */


.mean-container{
	position: relative;
	width: 100%;
}

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
	display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
	float: left;
	width: 100%;
	position: relative;
	background: transparent;
	padding: 0;
	min-height: 22px;
	z-index: 5;
}

.mean-container a.meanmenu-reveal {
	width: 25px;
	height: 66px;
	padding: 0;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: #ffffff;
	text-decoration: none;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
	line-height: 66px;
	display:  flex;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	justify-content: center;
	flex-direction:  column;
}

.mean-container a.meanmenu-reveal.meanclose {
    top: 23px;
    font-size: 25px !important;
}

.mean-container a.meanmenu-reveal span {
	display: block;
	background: #ffffff;
	height: 3px;
	margin-top: 5px;
	width:  100%;
}
.mean-container a.meanmenu-reveal span:first-child{
	margin-top: 0;
}

.mean-container .mean-nav {
	float: none;
	width: 100%;
	background: #f1f1f1;
	margin-left: 0;
	margin-bottom: 0;
	margin: 0 auto;
	margin-top: 24px;
}

.mean-container .mean-nav ul {
	margin: 0;
	width: 100%;
	list-style-type: none;
	padding: 20px 0;
}

.mean-container .mean-nav > ul {
	height: 215px;
	overflow: hidden;
	overflow-y: auto;
	display: block;
	padding: 0;
}

.mean-container .mean-nav ul li {
    position: relative;
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
}

.mean-container .mean-nav ul li a {
    display: block;
    float: left;
    width: calc(100% - 60px);
    padding: 12px 30px;
    margin: 0;
    text-align: left;
	color: #222;
    border-top: 1px solid #383838;
    border-top: 1px solid rgba(0,0,0,0.04);
    text-decoration: none;
    text-transform: capitalize;
    font-size: 14px;
}
.mean-container .mean-nav ul li a:hover{
	color: #2d3691
}


.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 5px 10%;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(0,0,0,0.03);
	opacity: 1;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 5px 15%;
}

.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255,255,255,0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
    margin-top: 1px;
    width: 26px;
    height: 30px;
    padding: 9px !important;
    text-align: center;
    position: absolute;
	right: 1px;
    top: 0;
    z-index: 2;
    font-weight: 700;
    background: rgba(255,255,255,0.1);
    border: none !important;
    border-left: 1px solid rgba(0,0,0,0.1) !important;
    border-bottom: 1px solid rgba(255,255,255,0.1) !important;
    line-height: 35px;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
	background: #e1e1e1;
}

.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}

.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
.mean-remove {
	display: none !important;
}
.mean-container a.meanmenu-reveal.meanclose {
    top: 0;
    font-size: 20px !important;
}
.mobile-menu a.logo {
	display:  inline-block;
    position:  absolute;
    z-index:  99;
    left:  0;
    top: 0;
    height:  66px;
    line-height: 66px;
}
.mobile-menu a.logo img{
	max-height: 100%;
}

.mean-container .mean-nav ul li a:hover {
    background:  #1e1b3d;
}
